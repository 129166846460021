h2 {
    font-size: 18px;
    font-weight: bold;
    color: $dark;
}

h4 {
    font-size: 14px;
    font-style: italic;
    color: $silver;
    margin-bottom: 20px;
}

.footer {
    margin-top: 50px;
    color: $dark-mid;
    @media(min-width: $screen-sm) {
        margin-top: 150px;
        padding-right: 0;
        padding-left: 0;
    }
}

.footer-list {
    margin-top: 20px;

    a {
        color: $light;

        &:focus,
        &:hover {
            color: $dark;
            text-decoration: none;
        }
    }

    .active {
        a {
            color: $dark;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .fa {
        color: $dark;
        font-size: 20px;
    }
}
