.gallery {
    max-width: 800px;
    margin: 0 auto;
    width: calc(100% - 30px);
    margin-bottom: 60px;
    @media(min-width: $screen-sm) {
        width: 100%;
    }
    @media(min-width: $screen-lg) {
        margin-bottom: 0;
    }

    &__title {
        color: $light;
        margin-bottom: 30px;

        span {
            color: $dark;
        }
    }

    &__left {
        position: absolute;

        &:before {
            position: absolute;
            top: calc(100% + 30px);
            left: 0;
            content: url("../img/left.svg");
            @media(min-width: $screen-lg) {
                top: calc(50% - 15px);
            }
        }
    }

    &__right {
        position: absolute;

        &:before {
            position: absolute;
            top: calc(100% + 30px);
            right: 0;
            content: url("../img/right.svg");
            @media(min-width: $screen-lg) {
                top: calc(50% - 15px);
            }
        }
    }

    &__close {
        z-index: 10;
        position: absolute;
        right: 0;
        top: 0;

        &:before {
            display: block;
            content: url("../img/x.svg");
        }
    }

    .carousel-control {
        text-align: center;
        width: 30px;

        .fa {
            position: absolute;
            top: calc(50% - 40px);
            color: #ffffff;
            left: 0;
            @media(min-width: $screen-lg) {
                color: $dark;
            }
        }

        &.right {
            background-image: none;
            @media(min-width: $screen-lg) {
                right: -80px;
            }
        }

        &.left {
            background-image: none;
            @media(min-width: $screen-lg) {
                left: -80px;
            }
        }
    }
}
