.container-for-grid {
    max-width: 990px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    @media(min-width: $screen-md) {
        padding-top: 75px;
        padding-bottom: 75px;
    }
}

.grid {
    max-width: 990px;
    margin: 0 auto;

    &-title {
        font-weight: bold;
        font-size: 18px;
    }

    &-item {
        margin-top: 15px;
        margin-bottom: 15px;
        opacity: 0;
        transition: opacity .5s ease-in;
    }

    &.is-showing-items {
        .grid-item {
            opacity: 1;

            img:hover {
              opacity: .85 !important;
            }
        }
    }
}

.bg-gray {
    background-color: #f7f7f7;
    // background-color: red;
}
