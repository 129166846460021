@media (min-width: 768px) {
    .navbar-nav.navbar-center {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
    }
}

.navbar-portfolio {
    border: 0;
    background-color: white;
    color: $dark;
    margin-bottom: 50px;

    .navbar-brand {
        font-weight: bold;
        color: $dark;
    }

    .is-checked {
      color: $dark !important;
    }
}

.navbar-default .navbar-nav > li > a {
    color: $light;
}

.navbar-portfolio .navbar-nav > .active > a,
.navbar-portfolio .navbar-nav > .active > a:focus,
.navbar-portfolio .navbar-nav > .active > a:hover {
    color: $dark;
    background-color: transparent;
}

.navbar-portfolio .navbar-right > .active > a {
  font-weight: 600;
  text-decoration: underline;
}

.navbar-portfolio .fa {
  font-size: 20px;
  color: $dark;
}
