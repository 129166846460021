.contact {
    margin-bottom: 50px;

    &__image-wrapper {
        position: relative;
        padding-top: 40%;
        overflow: hidden;
        margin-bottom: 50px;
    }

    &__image {
        position: absolute;
        max-width: 100%;
        top: 0;
        left: 0;
    }

    &__title {
        font-size: 26px;
        font-weight: bold;
        color: $dark;
    }

    &__intro {
        font-style: italic;
        color: $light;
    }

    &__desc {
        color: $dark-mid;
    }
}
