body,
html {
    font-family: 'PT Sans', sans-serif;
    color: $dark;
    padding: 15px 0;
}

a {
    color: $dark;

    &:focus,
    &:hover {
        color: $dark;
    }
}

.relative {
    position: relative;
}
