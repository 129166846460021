.fine-art {
    margin-bottom: 50px;

    &__wrapper {
        position: relative;
        padding-top: 75%;
        overflow: hidden;

        &--desc {
            @media(max-width: $screen-sm) {
                padding-top: 15px;

                .fine-art__content {
                    position: relative;
                }
            }
        }
    }

    &__content {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__image {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
    }

    &__title {
        font-size: 18px;
        font-weight: bold;
        color: $dark;
    }

    &__intro {
        font-style: italic;
        color: $light;
    }

    &__desc {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.btn-black {
    border: 0;
    border-radius: 0;
    background-color: $dark;
    color: #ffffff;

    &:focus,
    &:hover {
        color: $dark;
        background-color: #ffffff;
        outline: 1px solid $dark;
        outline-offset: -1px;
    }
}
